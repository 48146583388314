export default {
  'login.tenant': '企业',
  'login.account.i': '账号',
  'login.account.placeholder': '手机号或者邮箱',
  'login.password': '密码',
  'login.code': '验证码',
  'login.btn': '立即登录',
  'login.forget': '忘记密码',
  'login.copyright': '',
  'login.title': '欢迎使用',
};
